"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toDashboardPanelResponse = exports.DashboardPanelDef = exports.DashboardPanelPropertiesDef = exports.DashboardPanelType = exports.DashboardPanelIconColor = exports.DashboardPanelIcon = void 0;
const typebox_1 = require("@sinclair/typebox");
const timestampedModel_1 = require("./timestampedModel");
const typebox_2 = require("../typebox");
var DashboardPanelIcon;
(function (DashboardPanelIcon) {
    DashboardPanelIcon["CHECK"] = "check";
    DashboardPanelIcon["ALERT"] = "alert";
})(DashboardPanelIcon || (exports.DashboardPanelIcon = DashboardPanelIcon = {}));
var DashboardPanelIconColor;
(function (DashboardPanelIconColor) {
    DashboardPanelIconColor["GREEN"] = "green";
    DashboardPanelIconColor["ORANGE"] = "orange";
})(DashboardPanelIconColor || (exports.DashboardPanelIconColor = DashboardPanelIconColor = {}));
var DashboardPanelType;
(function (DashboardPanelType) {
    DashboardPanelType["CUSTOMER_LIFECYCLE"] = "customer_lifecycle";
    DashboardPanelType["REPORT"] = "report";
})(DashboardPanelType || (exports.DashboardPanelType = DashboardPanelType = {}));
exports.DashboardPanelPropertiesDef = typebox_1.Type.Object({
    order: typebox_1.Type.Number(),
    configuration: typebox_1.Type.Object({
        report_id: typebox_1.Type.String({ format: 'uuid' }),
        icon: (0, typebox_2.OptionalNullable)(typebox_1.Type.Enum(DashboardPanelIcon)),
        icon_color: (0, typebox_2.OptionalNullable)(typebox_1.Type.Enum(DashboardPanelIconColor)),
        type: (0, typebox_2.OptionalNullable)(typebox_1.Type.Enum(DashboardPanelType)),
    }),
}, { additionalProperties: false });
exports.DashboardPanelDef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: typebox_1.Type.String({ format: 'uuid' }),
        dashboard_id: typebox_1.Type.String({ format: 'uuid' }),
        workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    }),
    exports.DashboardPanelPropertiesDef,
    timestampedModel_1.TimestampedModelDef,
]);
const toDashboardPanelResponse = (dashboardPanel) => {
    return {
        id: dashboardPanel.id,
        order: dashboardPanel.order,
        configuration: dashboardPanel.configuration,
    };
};
exports.toDashboardPanelResponse = toDashboardPanelResponse;
