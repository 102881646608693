"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateWorkspaceObjectPropertyValueRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
exports.CreateWorkspaceObjectPropertyValueRequestDef = typebox_1.Type.Object({
    property_value: typebox_1.Type.String(),
    order: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
    workspace_id: typebox_1.Type.String(),
});
