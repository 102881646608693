"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateShopifyPartnersIntegrationRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.CreateShopifyPartnersIntegrationRequestDef = typebox_1.Type.Object({
    api_key: typebox_1.Type.String({
        description: 'Shopify Partners Access Token.',
    }),
    organization_id: typebox_1.Type.Number({
        description: 'Shopify Partners Organization ID.',
    }),
    app_id: typebox_1.Type.Number({
        description: 'Shopify App ID.',
    }),
    workspace_object_slug: typebox_1.Type.String({
        description: 'Workspace Object that should be used to track state of the Shopify Partners Customer.',
    }),
});
