"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentImportTransactionDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const timestampedModel_1 = require("../timestampedModel");
const types_1 = require("../../types");
exports.PaymentImportTransactionDef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: typebox_1.Type.String(),
        account_id: typebox_1.Type.String({ format: 'uuid' }),
        workspace_id: typebox_1.Type.String({ format: 'uuid' }),
        integration_id: typebox_1.Type.String({ format: 'uuid' }),
        subscription_id: typebox_1.Type.String(),
        type: typebox_1.Type.Enum(types_1.PaymentImportTransactionType),
        amount_in_cents: typebox_1.Type.Number(),
        currency_code: typebox_1.Type.String(),
        timestamp: typebox_1.Type.String({ format: 'date-time' }),
    }),
    timestampedModel_1.TimestampedModelDef,
]);
