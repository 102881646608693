"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentImportSubscriptionResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const paymentImportCustomerCompactResponse_1 = require("./paymentImportCustomerCompactResponse");
const paymentImportSubscription_1 = require("../../../model/payment-import/paymentImportSubscription");
exports.PaymentImportSubscriptionResponseDef = typebox_1.Type.Composite([
    typebox_1.Type.Omit(paymentImportSubscription_1.PaymentImportSubscriptionDef, ['customer_id']),
    typebox_1.Type.Object({
        customer: paymentImportCustomerCompactResponse_1.PaymentImportCustomerCompactResponseDef,
    }),
]);
