"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResourceType = exports.ResourceValueScope = exports.ResourcePropertyNonArrayTypes = exports.ResourcePropertyArrayTypes = exports.ResourcePropertyTypeType = exports.getEnumKeyByValue = exports.ResourcePropertyType = void 0;
const typebox_1 = require("../typebox");
const typebox_2 = require("@sinclair/typebox");
var ResourcePropertyType;
(function (ResourcePropertyType) {
    ResourcePropertyType["STRING"] = "string";
    ResourcePropertyType["NUMBER"] = "number";
    ResourcePropertyType["BOOLEAN"] = "boolean";
    ResourcePropertyType["DATETIME64"] = "datetime64";
    ResourcePropertyType["STRING_ARRAY"] = "string[]";
    ResourcePropertyType["NUMBER_ARRAY"] = "number[]";
    ResourcePropertyType["TIMESTAMP_ARRAY"] = "timestamp[]";
    ResourcePropertyType["BOOLEAN_ARRAY"] = "boolean[]";
    ResourcePropertyType["OBJECT"] = "object";
    ResourcePropertyType["CURRENCY"] = "currency";
    ResourcePropertyType["RELATION"] = "relation";
    ResourcePropertyType["RELATION_ARRAY"] = "relation[]";
    ResourcePropertyType["STAGE"] = "stage";
    ResourcePropertyType["MEMBER"] = "member";
})(ResourcePropertyType || (exports.ResourcePropertyType = ResourcePropertyType = {}));
function getEnumKeyByValue(value) {
    for (const [key, val] of Object.entries(ResourcePropertyType)) {
        if (val === value) {
            return ResourcePropertyType[key];
        }
    }
    return undefined;
}
exports.getEnumKeyByValue = getEnumKeyByValue;
exports.ResourcePropertyTypeType = (0, typebox_1.EnumStrings)(typebox_2.Type.Enum(ResourcePropertyType, {
    description: 'Property type. One of:' +
        'string, ' +
        'number, ' +
        'boolean, ' +
        'datetime, ' +
        'datetime utc, ' +
        'datetime64, ' +
        'string[], ' +
        'number[], ' +
        'timestamp[], ' +
        'boolean[], ' +
        'object, ' +
        'currency, ' +
        'relation, ' +
        'relations[], ' +
        'stage, ' +
        'member',
}));
exports.ResourcePropertyArrayTypes = [
    ResourcePropertyType.STRING_ARRAY,
    ResourcePropertyType.NUMBER_ARRAY,
    ResourcePropertyType.TIMESTAMP_ARRAY,
    ResourcePropertyType.BOOLEAN_ARRAY,
];
exports.ResourcePropertyNonArrayTypes = Object.values(ResourcePropertyType).filter((item) => !exports.ResourcePropertyArrayTypes.includes(item));
var ResourceValueScope;
(function (ResourceValueScope) {
    ResourceValueScope["ROOT"] = "ROOT";
    ResourceValueScope["PROPERTIES"] = "PROPERTIES";
})(ResourceValueScope || (exports.ResourceValueScope = ResourceValueScope = {}));
var ResourceType;
(function (ResourceType) {
    ResourceType["OBJECT"] = "OBJECT";
    ResourceType["EVENT"] = "EVENT";
})(ResourceType || (exports.ResourceType = ResourceType = {}));
