"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toEventsDataQueryResponse = exports.toEventDataQueryResponse = exports.EventsDataQueryResponseDef = exports.EventDataQueryResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const dataQueryPropertiesResponse_1 = require("./dataQueryPropertiesResponse");
exports.EventDataQueryResponseDef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
        event_name: typebox_1.Type.String(),
        properties: (0, typebox_2.OptionalNullable)(typebox_2.NestedObjectDef),
        relationships: (0, typebox_2.OptionalNullable)(dataQueryPropertiesResponse_1.DataQueryRelationshipsResponseDef),
        relations: (0, typebox_2.OptionalNullable)(typebox_1.Type.Record(typebox_1.Type.String(), typebox_1.Type.String())),
        created_at: typebox_1.Type.String({ format: 'date-time' }),
    }),
]);
exports.EventsDataQueryResponseDef = typebox_1.Type.Object({
    items: typebox_1.Type.Array(exports.EventDataQueryResponseDef),
    totalEventsCount: typebox_1.Type.Optional(typebox_1.Type.Number()),
    totalFilteredEventsCount: typebox_1.Type.Optional(typebox_1.Type.Number()),
});
const toEventDataQueryResponse = (event) => {
    return {
        id: event.id,
        event_name: event.eventName,
        properties: event.properties,
        relationships: event.relationships,
        relations: event.relations,
        created_at: event.createdAt,
    };
};
exports.toEventDataQueryResponse = toEventDataQueryResponse;
const toEventsDataQueryResponse = (records, totalEventsCount, totalFilteredEventsCount) => {
    return {
        items: records.map(exports.toEventDataQueryResponse),
        totalEventsCount: totalEventsCount,
        totalFilteredEventsCount: totalFilteredEventsCount,
    };
};
exports.toEventsDataQueryResponse = toEventsDataQueryResponse;
