"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.toShopifyPartnersIntegrationResponse = exports.ShopifyPartnersIntegrationResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_1 = require("../../../model");
const integrationResponse_1 = require("../integrationResponse");
exports.ShopifyPartnersIntegrationResponseDef = typebox_1.Type.Composite([
    integrationResponse_1.IntegrationResponseDef,
    typebox_1.Type.Object({
        configuration: model_1.ShopifyPartnersIntegrationConfigurationDef,
        state: model_1.ShopifyPartnersIntegrationStateDef,
    }),
]);
const toShopifyPartnersIntegrationResponse = (integration, memberResponseMapper) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    return Object.assign(Object.assign({}, (yield (0, integrationResponse_1.toIntegrationResponse)(integration, memberResponseMapper))), { configuration: Object.assign({}, integration.configuration.shopify_partners), state: Object.assign({}, (_a = integration.state) === null || _a === void 0 ? void 0 : _a.shopify_partners) });
});
exports.toShopifyPartnersIntegrationResponse = toShopifyPartnersIntegrationResponse;
