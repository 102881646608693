"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapToReportQueryResultVO = exports.mapToReportsQueryResponse = exports.ReportsQueryResponseDef = exports.ReportSingletQueryResultVODef = exports.ReportQueryResultMetadataVODef = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_1 = require("../model");
const value_object_1 = require("../value-object");
const typebox_2 = require("../typebox");
exports.ReportQueryResultMetadataVODef = typebox_1.Type.Object({
    format: typebox_1.Type.Optional(value_object_1.ReportsMetricQueryFormatVODef),
    aggregation: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
        result: typebox_1.Type.Array(typebox_1.Type.Record(typebox_1.Type.String(), typebox_1.Type.Any())),
    })),
    funnel: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
        time_to_complete: typebox_1.Type.Array(typebox_1.Type.Record(typebox_1.Type.String(), (0, typebox_2.OptionalNullable)(typebox_1.Type.Any()))),
        events_per_stage: typebox_1.Type.Array(typebox_1.Type.Record(typebox_1.Type.String(), (0, typebox_2.OptionalNullable)(typebox_1.Type.Any()))),
    })),
    query_name: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    name: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
});
exports.ReportSingletQueryResultVODef = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    duration: typebox_1.Type.Number(),
    metadata: typebox_1.Type.Optional(exports.ReportQueryResultMetadataVODef),
    result: typebox_1.Type.Array(typebox_1.Type.Record(typebox_1.Type.String(), typebox_1.Type.Any())),
    previous_period_result: typebox_1.Type.Optional(typebox_1.Type.Array(typebox_1.Type.Record(typebox_1.Type.String(), typebox_1.Type.Any()))),
});
exports.ReportsQueryResponseDef = typebox_1.Type.Object({
    id: typebox_1.Type.String({ format: 'uuid' }),
    workspace_id: typebox_1.Type.String({ format: 'uuid' }),
    status: typebox_1.Type.Enum(model_1.DataInsightsQueryStatus),
    query_ids: typebox_1.Type.Array(typebox_1.Type.String({ format: 'uuid' })),
    queries: typebox_1.Type.Array(exports.ReportSingletQueryResultVODef),
});
const mapToReportsQueryResponse = (query, results) => {
    const { id, workspaceId, status, dataQueryIds } = query;
    return {
        id,
        workspace_id: workspaceId,
        status,
        query_ids: dataQueryIds,
        queries: (results || []).map(exports.mapToReportQueryResultVO),
    };
};
exports.mapToReportsQueryResponse = mapToReportsQueryResponse;
const mapToReportQueryResultVO = (query) => {
    const { id, result } = query;
    return {
        id,
        duration: (result === null || result === void 0 ? void 0 : result.duration) || 0,
        result: (result === null || result === void 0 ? void 0 : result.queryResult) || [],
    };
};
exports.mapToReportQueryResultVO = mapToReportQueryResultVO;
