"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toCompactAutomationResponse = exports.CompactAutomationResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const automationResponse_1 = require("./automationResponse");
exports.CompactAutomationResponseDef = typebox_1.Type.Pick(automationResponse_1.AutomationResponseDef, ['id', 'name']);
const toCompactAutomationResponse = (automation) => {
    if (!automation) {
        return null;
    }
    return {
        id: automation.id,
        name: automation.name,
    };
};
exports.toCompactAutomationResponse = toCompactAutomationResponse;
