"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableLayoutConfigDef = exports.ColumnConfigDef = exports.TrendColumnDef = exports.RelationshipColumnDef = exports.PropertyColumnDef = exports.FieldColumnDef = exports.TableSortDirection = exports.TableColumnType = exports.TableResourceType = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const timestampedModel_1 = require("./timestampedModel");
const types_1 = require("../types");
const value_object_1 = require("../value-object");
var TableResourceType;
(function (TableResourceType) {
    TableResourceType["OBJECT"] = "object";
    TableResourceType["EVENT"] = "event";
})(TableResourceType || (exports.TableResourceType = TableResourceType = {}));
var TableColumnType;
(function (TableColumnType) {
    TableColumnType["FIELD"] = "field";
    TableColumnType["PROPERTY"] = "property";
    TableColumnType["RELATIONSHIP"] = "relationship";
    TableColumnType["LABEL"] = "label";
    TableColumnType["TREND"] = "trend";
})(TableColumnType || (exports.TableColumnType = TableColumnType = {}));
var TableSortDirection;
(function (TableSortDirection) {
    TableSortDirection["ASC"] = "asc";
    TableSortDirection["DESC"] = "desc";
})(TableSortDirection || (exports.TableSortDirection = TableSortDirection = {}));
exports.FieldColumnDef = typebox_1.Type.Object({
    field_name: typebox_1.Type.String(),
});
exports.PropertyColumnDef = typebox_1.Type.Object({
    property_id: typebox_1.Type.String(),
    property_name: typebox_1.Type.String(),
    property_type: (0, typebox_2.OptionalNullable)(types_1.ResourcePropertyTypeType),
});
exports.RelationshipColumnDef = typebox_1.Type.Recursive((Self) => typebox_1.Type.Object({
    type: typebox_1.Type.Enum(TableColumnType),
    name: typebox_1.Type.String(),
    [TableColumnType.FIELD]: (0, typebox_2.OptionalNullable)(exports.FieldColumnDef),
    [TableColumnType.PROPERTY]: (0, typebox_2.OptionalNullable)(exports.PropertyColumnDef),
    [TableColumnType.RELATIONSHIP]: (0, typebox_2.OptionalNullable)(Self),
    [TableColumnType.TREND]: (0, typebox_2.OptionalNullable)(exports.TrendColumnDef),
}), {
    $id: 'RelationshipColumnDef',
});
exports.TrendColumnDef = typebox_1.Type.Object({
    type: typebox_1.Type.Enum(types_1.TrendType),
    time: value_object_1.TimeRangeVODef,
});
exports.ColumnConfigDef = typebox_1.Type.Object({
    id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
    order: typebox_1.Type.Number(),
    sort: (0, typebox_2.OptionalNullable)(typebox_1.Type.Enum(TableSortDirection)),
    type: typebox_1.Type.Enum(TableColumnType),
    width: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
    [TableColumnType.FIELD]: (0, typebox_2.OptionalNullable)(exports.FieldColumnDef),
    [TableColumnType.PROPERTY]: (0, typebox_2.OptionalNullable)(exports.PropertyColumnDef),
    [TableColumnType.RELATIONSHIP]: (0, typebox_2.OptionalNullable)(exports.RelationshipColumnDef),
    [TableColumnType.TREND]: (0, typebox_2.OptionalNullable)(exports.TrendColumnDef),
});
exports.TableLayoutConfigDef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
        account_id: typebox_1.Type.String({ format: 'uuid' }),
        workspace_id: typebox_1.Type.String({ format: 'uuid' }),
        member_id: typebox_1.Type.String({ format: 'uuid' }),
        resource_type: typebox_1.Type.Enum(TableResourceType),
        resource_id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
        columns: typebox_1.Type.Array(exports.ColumnConfigDef),
    }),
    timestampedModel_1.TimestampedModelDef,
]);
