"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toRecordPropertyModificationResponse = exports.RecordPropertyModificationResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.RecordPropertyModificationResponseDef = typebox_1.Type.Object({
    path: typebox_1.Type.String(),
    updatedValue: typebox_1.Type.Array(typebox_1.Type.String()),
});
const toRecordPropertyModificationResponse = (path, updatedValue) => {
    return {
        path: path,
        updatedValue: updatedValue,
    };
};
exports.toRecordPropertyModificationResponse = toRecordPropertyModificationResponse;
