"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthorResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const memberResponse_1 = require("./memberResponse");
const compactTrackingKeyResponse_1 = require("./compactTrackingKeyResponse");
const compactApiKeyResponse_1 = require("./compactApiKeyResponse");
const compactAutomationResponse_1 = require("./compactAutomationResponse");
const compactIntegrationResponse_1 = require("./compactIntegrationResponse");
const types_1 = require("../types");
exports.AuthorResponseDef = typebox_1.Type.Object({
    type: typebox_1.Type.Enum(types_1.AuthorType),
    [types_1.AuthorType.member]: (0, typebox_2.OptionalNullable)(memberResponse_1.MemberResponseDef),
    [types_1.AuthorType.tracking_key]: (0, typebox_2.OptionalNullable)(compactTrackingKeyResponse_1.CompactTrackingKeyResponseDef),
    [types_1.AuthorType.api_key]: (0, typebox_2.OptionalNullable)(compactApiKeyResponse_1.CompactApiKeyResponseDef),
    [types_1.AuthorType.integration]: (0, typebox_2.OptionalNullable)(compactIntegrationResponse_1.CompactIntegrationResponseDef),
    [types_1.AuthorType.automation]: (0, typebox_2.OptionalNullable)(compactAutomationResponse_1.CompactAutomationResponseDef),
});
