import React, { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import { Navigation } from '../features/navigation/components/Navigation';
import { useDrawerStore } from '../features/navigation/store';
import { twMerge } from 'tailwind-merge';

export const Layout: React.FC<PropsWithChildren> = () => {
  const { isOpen } = useDrawerStore();

  return (
    <>
      <Navigation />
      <div className={twMerge(isOpen ? 'ml-52' : 'ml-0', 'flex h-screen max-h-screen flex-col overflow-hidden transition-all')}>
        <Outlet />
      </div>
    </>
  );
};
