import { WorkspaceProvider } from '../context/workspaceContext.tsx';
import { useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { UserContext } from '../context/userContext.tsx';

export const WorkspaceContextRoute = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);

  useEffect(() => {
    if (userContext.ready) {
      if (!userContext.workspaces?.length) {
        // TODO: Should this default to the first account?
        const firstAccountId = userContext.accounts?.[0]?.id;

        navigate('/workspaces/onboarding/' + firstAccountId);
      }
    }
  }, [
    navigate,
    userContext.ready,
    userContext.authenticated,
    userContext.user,
    userContext.accounts?.length,
    userContext.workspaces?.length,
    userContext.accounts,
  ]);

  const defaultWorkspaceId = userContext.workspaces?.[0]?.id;

  if (!userContext.ready || !userContext.authenticated || !defaultWorkspaceId) {
    return null;
  }

  return (
    <WorkspaceProvider defaultWorkspaceId={defaultWorkspaceId}>
      <Outlet />
    </WorkspaceProvider>
  );
};
