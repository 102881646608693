"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toCompactTrackingKeyResponse = exports.CompactTrackingKeyResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const trackingKeyResponse_1 = require("./trackingKeyResponse");
exports.CompactTrackingKeyResponseDef = typebox_1.Type.Pick(trackingKeyResponse_1.TrackingKeyResponseDef, ['id', 'name']);
const toCompactTrackingKeyResponse = (trackingKey) => {
    if (!trackingKey) {
        return null;
    }
    return {
        id: trackingKey.id,
        name: trackingKey.name,
    };
};
exports.toCompactTrackingKeyResponse = toCompactTrackingKeyResponse;
