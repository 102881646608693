"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentImportCustomerDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const timestampedModel_1 = require("../timestampedModel");
exports.PaymentImportCustomerDef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: typebox_1.Type.String(),
        account_id: typebox_1.Type.String({ format: 'uuid' }),
        workspace_id: typebox_1.Type.String({ format: 'uuid' }),
        integration_id: typebox_1.Type.String({ format: 'uuid' }),
        name: typebox_1.Type.String(),
    }),
    timestampedModel_1.TimestampedModelDef,
]);
