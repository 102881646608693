"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpsertWorkspaceObjectRecordVODef = void 0;
const typebox_1 = require("../typebox");
const typebox_2 = require("@sinclair/typebox");
const model_1 = require("../model");
exports.UpsertWorkspaceObjectRecordVODef = typebox_2.Type.Object({
    remoteId: typebox_2.Type.String(),
    workspaceId: typebox_2.Type.String({ format: 'uuid' }),
    workspaceObjectIdOrSlug: typebox_2.Type.String({ format: 'uuid' }),
    properties: (0, typebox_1.OptionalNullable)(typebox_1.NestedObjectDef),
    propertiesPathToRemove: (0, typebox_1.OptionalNullable)(typebox_2.Type.Array(typebox_2.Type.String())),
    activeSince: (0, typebox_1.OptionalNullable)(typebox_1.DateType),
    modifiedBy: model_1.AuthorDef,
});
