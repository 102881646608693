import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useAccount } from '../../auth/hooks/useAccount';
import { useUser } from '../../auth/hooks/useUser';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { forwardRef } from 'react';
import { twMerge } from '../../../utils/twMerge';
import ChevronRightIcon from '../../../assets/icons/chevron-right.svg?react';
import { ProfileMenuAccountSection } from './ProfileMenuAccountSection';

const DropdownMenuSubTrigger = forwardRef<HTMLDivElement, DropdownMenu.DropdownMenuSubTriggerProps>(({ children, className, ...props }, ref) => (
  <DropdownMenu.SubTrigger
    className={twMerge(
      'flex select-none items-center gap-x-2 rounded px-2 py-1.5 text-m-olive-800 outline-none radix-highlighted:bg-m-gray-200',
      className
    )}
    {...props}
    ref={ref}
  >
    {children}
  </DropdownMenu.SubTrigger>
));

export const AccountSubmenu = () => {
  const { workspaces, accounts } = useUser();
  const { currentWorkspaceId } = useWorkspace();
  const activeWorkspace = workspaces?.find((w) => w.id === currentWorkspaceId);

  const { currentAccountName } = useAccount();

  return (
    <DropdownMenu.Sub>
      <DropdownMenuSubTrigger>
        <div className="flex grow flex-col">
          <div className="text-xs">{`Workspace: ${activeWorkspace?.name}`}</div>
          <div className="text-xxs text-m-olive-400">{`Account: ${currentAccountName}`}</div>
        </div>
        <ChevronRightIcon className="flex size-4 shrink-0 text-m-olive-300" />
      </DropdownMenuSubTrigger>
      <DropdownMenu.Portal>
        <DropdownMenu.SubContent className="w-52 rounded-md border border-m-olive-100 bg-m-white p-2 shadow-sm">
          {accounts?.map((account, index) => {
            const accountWorkspaces = workspaces?.filter((workspace) => workspace.account_id == account.id);
            const activeWorkspaceInCurrentAccount = accountWorkspaces?.some((w) => w.id === currentWorkspaceId);
            return (
              <span key={account.id}>
                <ProfileMenuAccountSection
                  account={account}
                  workspaces={accountWorkspaces}
                  isInitialExpanded={activeWorkspaceInCurrentAccount}
                  activeWorkspace={activeWorkspace}
                />
                {index < accounts.length - 1 && <DropdownMenu.Separator className="my-1.5 h-px bg-m-olive-100" />}
              </span>
            );
          })}
        </DropdownMenu.SubContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Sub>
  );
};
