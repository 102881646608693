"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cacheKeys = void 0;
const workspaceMetadata = (workspaceId) => `${workspace(workspaceId)} metadata`;
const workspace = (id) => `workspace-workspaceId:${id}`;
const paymentIntegrationCustomerRecordId = (workspaceId, customerId) => `workspace-workspaceId-${workspaceId}:payment-integration-customer-id-${customerId}`;
exports.cacheKeys = {
    workspaceMetadata,
    workspace,
    paymentIntegrationCustomerRecordId,
};
