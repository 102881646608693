"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportVODef = exports.ReportDataUsedVODef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const model_1 = require("../model");
const reportsQueryRequest_1 = require("./reportsQueryRequest");
const types_1 = require("../types");
const reportGroupingConfigVO_1 = require("./reportGroupingConfigVO");
const reportTemplatesQueryRequest_1 = require("./reportTemplatesQueryRequest");
const chartColorMode_1 = require("../types/chartColorMode");
exports.ReportDataUsedVODef = typebox_1.Type.Object({
    objects: typebox_1.Type.Optional(typebox_1.Type.Array(typebox_1.Type.String())),
    events: typebox_1.Type.Optional(typebox_1.Type.Array(typebox_1.Type.String())),
});
exports.ReportVODef = typebox_1.Type.Composite([
    model_1.TimestampedModelDef,
    typebox_1.Type.Object({
        id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
        accountId: typebox_1.Type.String({ format: 'uuid' }),
        workspaceId: typebox_1.Type.String({ format: 'uuid' }),
        title: typebox_1.Type.Optional(typebox_1.Type.String()),
        description: typebox_1.Type.Optional(typebox_1.Type.String()),
        details: typebox_1.Type.Optional(typebox_1.Type.String()),
        owner: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
        documentGroupIds: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(typebox_1.Type.String({ format: 'uuid' }))),
        createdBy: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
        updatedBy: typebox_1.Type.Optional(typebox_1.Type.String({ format: 'uuid' })),
        type: (0, typebox_2.OptionalNullable)(typebox_1.Type.Enum(types_1.ReportTypeVO)),
        query: (0, typebox_2.OptionalNullableRef)(typebox_1.Type.Ref(reportsQueryRequest_1.ReportsQueryRequestDef)),
        groupingConfig: typebox_1.Type.Optional(reportGroupingConfigVO_1.ReportGroupingConfigVODef),
        context: (0, typebox_2.OptionalNullable)(typebox_1.Type.Enum(types_1.Context)),
        template: (0, typebox_2.OptionalNullable)(reportTemplatesQueryRequest_1.ReportTemplatesQueryRequestDef),
        isHidden: (0, typebox_2.OptionalNullable)(typebox_1.Type.Boolean()),
        colorMode: (0, typebox_2.OptionalNullable)(typebox_1.Type.Enum(chartColorMode_1.ChartColorMode)),
    }),
]);
