"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LifecycleStage = void 0;
var LifecycleStage;
(function (LifecycleStage) {
    LifecycleStage["CUSTOMER"] = "Customer";
    LifecycleStage["INTERESTED"] = "Interested";
    LifecycleStage["AWARE"] = "Aware";
    LifecycleStage["IDENTIFIED"] = "Identified";
})(LifecycleStage || (exports.LifecycleStage = LifecycleStage = {}));
