import React from 'react';
import { Button } from '../ui/Button/Button';
import AlertTriangleIcon from '../../assets/icons/alert-triangle.svg?react';

interface ErrorFallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

export const ErrorFallback: React.FC<ErrorFallbackProps> = ({ resetErrorBoundary }) => {
  return (
    <div className="z-[99999] flex min-h-screen flex-col items-center justify-center bg-m-white text-center">
      <AlertTriangleIcon className="mb-6 h-12 w-12 text-m-orange-600" />
      <h1 className="mb-2 text-display-sm font-medium text-m-olive-900">We're sorry, something went wrong.</h1>
      <p className="mb-6 text-lg text-m-olive-500">Please try reloading the application to continue.</p>
      <Button
        intent="brand"
        size="lg"
        label="Reload application"
        onClick={() => {
          resetErrorBoundary();
          window.location.href = '/';
        }}
      />
    </div>
  );
};
