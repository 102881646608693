import { FC } from 'react';
import { twMerge } from '../../../utils/twMerge';
import { PropsWithClassName } from '../../../types';

interface DividerProps extends PropsWithClassName {
  direction?: 'horizontal' | 'vertical';
}

export const Divider: FC<DividerProps> = ({ direction = 'horizontal', className }) => {
  return <hr className={twMerge('shrink-0 bg-m-olive-100', direction === 'horizontal' ? 'h-px w-full' : 'h-full w-px', className)} />;
};
