"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthorType = void 0;
var AuthorType;
(function (AuthorType) {
    AuthorType["member"] = "member";
    AuthorType["tracking_key"] = "tracking_key";
    AuthorType["api_key"] = "api_key";
    AuthorType["integration"] = "integration";
    AuthorType["automation"] = "automation";
    AuthorType["system"] = "system";
})(AuthorType || (exports.AuthorType = AuthorType = {}));
