import { twMerge } from '../../../utils/twMerge';
import { NavItemGroup } from '../../navigation/components/NavItemGroup';

import BackIcon from '../../../assets/icons/arrow-circle-left.svg?react';
import { Link } from 'react-router-dom';
import { NavItem } from '../../navigation/components/NavItem';
import { AppRoutes, ObjectsRoutes } from '../../../routes';

export const SettingsNavigation = () => {
  return (
    <div className={twMerge('fixed z-50 flex h-screen w-52 flex-col gap-y-4 bg-m-coconut-600 px-5 py-4 transition-all')}>
      <div className="h-full overflow-y-auto">
        <div className="flex items-center gap-x-2 py-5">
          <Link to="/">
            <BackIcon className="h-5 w-5 text-m-olive-400" />
          </Link>
          <span className="text-lg font-medium text-m-olive-600">Settings</span>
        </div>
        <NavItemGroup heading="Account">
          <NavItem to={AppRoutes.SETTINGS_PROFILE} label="Profile" />
          <NavItem to={AppRoutes.MEMBERS} label="Team members" />
          <NavItem to={AppRoutes.BILLING} label="Plan & Billing" />
        </NavItemGroup>
        <NavItemGroup heading="Workspace">
          <NavItem to={AppRoutes.SETTINGS_WORKSPACE_GENERAL} label="General" />
          <NavItem to={ObjectsRoutes.SETTINGS_WORKSPACE_LIST} label="Data" />
          <NavItem to={AppRoutes.INTEGRATIONS} label="Integrations" />
          <NavItem to={AppRoutes.ACCESS_KEYS} label="Access keys" />
        </NavItemGroup>
      </div>
    </div>
  );
};
