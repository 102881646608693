export enum TrackingEvent {
  SignupStarted = 'Signup Started',
  SignupStepOneCompleted = 'Signup Step 1 Completed',
  SignupStepTwoCompleted = 'Signup Step 2 Completed',
  SignupStepThreeCompleted = 'Signup Step 3 Completed',
  SignupBusinessModelSelected = 'Signup Business Model Selected',
  LoggedIn = 'Logged In',
  LoggedOut = 'Logged Out',
  DashboardViewed = 'Dashboard Viewed',
  ReportsAddedToDashboard = 'Reports Added to Dashboard',
  DashboardReportClicked = 'Dashboard Report Clicked',
  GraphViewed = 'Graph Viewed',
  GraphMetricClicked = 'Graph Metric Clicked',
  GraphCardAdded = 'Graph Card Added',
  MetricRulesEdited = 'Metric Rules Edited',
  GraphMetricAddGoalClicked = 'Graph Metric Add Goal Clicked',
  GraphCardDeleted = 'Graph Card Deleted',
  ListViewed = 'List Viewed',
  RecordListFiltered = 'Record List Filtered',
  EventListFiltered = 'Event List Filtered',
  RecordSegmentSaved = 'Record Segment Saved',
  ReportOpened = 'Report Opened',
  StartedCreatingReport = 'Started Creating Report',
  ReportCreated = 'Report Created',
  ReportDataSelected = 'Report Data Selected',
  ReportFilterUpdated = 'Report Filter Updated',
  ReportBreakdownUpdate = 'Report Breakdown Update',
  ReportPresented = 'Report Presented',
  ReportSaved = 'Report Saved',
  StripeIntegrationInitiated = 'Stripe Integration Initiated',
  WorkspaceCreationInitialized = 'Workspace Creation Initialized',
  WorkspaceCreated = 'Workspace Created',
  WorkspaceSwitched = 'Workspace Switched',
  PlanningNotifyRequested = 'Requested for Planning Notification',
  IntegrationRequested = 'Integration Requested',
  InvitationSent = 'Invitation Sent',
  InvitationAccepted = 'Invitation Accepted',
  AutomationViewed = 'Automation Viewed',
  AutomationCreated = 'Automation Created',
  AutomationUpdated = 'Automation Updated',
  AutomationEnabled = 'Automation Enabled',
  AutomationDisabled = 'Automation Disabled',
}
