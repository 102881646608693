"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreatePayPalIntegrationRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.CreatePayPalIntegrationRequestDef = typebox_1.Type.Object({
    client_id: typebox_1.Type.String({
        description: 'PayPal App Client ID.',
    }),
    client_secret: typebox_1.Type.String({
        description: 'PayPal App Client Secret.',
    }),
    workspace_object_slug: typebox_1.Type.String({
        description: 'Workspace Object that should be used to track state of the PayPal Customer.',
    }),
});
