"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreatePayMeIntegrationRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
exports.CreatePayMeIntegrationRequestDef = typebox_1.Type.Object({
    api_key: typebox_1.Type.String({
        description: 'PayMe API Key.',
    }),
    workspace_object_slug: typebox_1.Type.String({
        description: 'Workspace Object that should be used to track state of the PayMe Customer.',
    }),
});
