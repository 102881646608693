"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toTimeShiftUnit = exports.TimeRangeWithShiftVODef = exports.TimeShiftVODef = exports.TimeRangeVODef = exports.TimeRangeWindowVODef = exports.GroupValueTypeType = exports.GroupValueType = exports.TimeShiftUnitType = exports.TimeShiftUnit = exports.DateRangeTypeType = exports.DateRangeType = exports.ChartType = exports.TimeGranularity = exports.TimeUnitType = exports.TimeUnit = exports.MetricMathType = exports.MetricMath = exports.EventsDataQueryFilterType = exports.TrackedEventFilterResourceType = exports.StandardMetric = exports.MetricResourceType = exports.GroupOperatorType = exports.GroupOperator = exports.MetricFilterOperatorType = exports.MetricFilterOperator = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
var MetricFilterOperator;
(function (MetricFilterOperator) {
    MetricFilterOperator["EQUALS"] = "equals";
    MetricFilterOperator["NOT_EQUALS"] = "not equals";
    MetricFilterOperator["CONTAINS"] = "contains";
    MetricFilterOperator["NOT_CONTAINS"] = "not contains";
})(MetricFilterOperator || (exports.MetricFilterOperator = MetricFilterOperator = {}));
exports.MetricFilterOperatorType = (0, typebox_2.EnumStrings)(typebox_1.Type.Enum(MetricFilterOperator));
var GroupOperator;
(function (GroupOperator) {
    GroupOperator["EQUALS"] = "equals";
    GroupOperator["IN"] = "in";
})(GroupOperator || (exports.GroupOperator = GroupOperator = {}));
exports.GroupOperatorType = (0, typebox_2.EnumStrings)(typebox_1.Type.Enum(GroupOperator));
var MetricResourceType;
(function (MetricResourceType) {
    MetricResourceType["EVENTS"] = "events";
    MetricResourceType["RECORDS"] = "records";
    MetricResourceType["PREDEFINED"] = "predefined";
    MetricResourceType["RETENTION"] = "retention";
    MetricResourceType["METRIC"] = "metric";
    MetricResourceType["FORMULA"] = "formula";
    MetricResourceType["FUNNEL"] = "funnel";
})(MetricResourceType || (exports.MetricResourceType = MetricResourceType = {}));
var StandardMetric;
(function (StandardMetric) {
    StandardMetric["TOTAL_SUBSCRIBERS"] = "total subscribers";
    StandardMetric["MRR"] = "mrr";
})(StandardMetric || (exports.StandardMetric = StandardMetric = {}));
var TrackedEventFilterResourceType;
(function (TrackedEventFilterResourceType) {
    TrackedEventFilterResourceType["EVENT_NAME"] = "event_name";
    TrackedEventFilterResourceType["EVENT_PROPERTY"] = "event_property";
})(TrackedEventFilterResourceType || (exports.TrackedEventFilterResourceType = TrackedEventFilterResourceType = {}));
var EventsDataQueryFilterType;
(function (EventsDataQueryFilterType) {
    EventsDataQueryFilterType["EVENT_FIELD"] = "event_field";
    EventsDataQueryFilterType["EVENT_PROPERTY"] = "event_property";
    EventsDataQueryFilterType["EVENT_NAME"] = "event_name";
    EventsDataQueryFilterType["RELATED_RECORDS"] = "related_records";
    EventsDataQueryFilterType["PARTITION"] = "partition";
})(EventsDataQueryFilterType || (exports.EventsDataQueryFilterType = EventsDataQueryFilterType = {}));
var MetricMath;
(function (MetricMath) {
    MetricMath["DISTINCT_TOTAL"] = "distinct total";
    MetricMath["TOTAL"] = "total";
    MetricMath["SUM"] = "sum";
    MetricMath["MIN"] = "min";
    MetricMath["AVERAGE"] = "average";
    MetricMath["MAX"] = "max";
    MetricMath["MEDIAN"] = "median";
    MetricMath["NPS"] = "nps";
    MetricMath["LATEST_VALUE"] = "latest value";
    MetricMath["TOTAL_COUNT_PER_GRANULARITY"] = "total count per granularity";
    MetricMath["SUM_LATEST_VALUES_PER_GROUP"] = "sum latest values per group";
    MetricMath["AVERAGE_GROUP_SUM_VALUES"] = "avg group sum values";
    MetricMath["AVERAGE_PER_RELATION"] = "average per relation";
    MetricMath["ROLLING_AVERAGE"] = "rolling average";
})(MetricMath || (exports.MetricMath = MetricMath = {}));
exports.MetricMathType = (0, typebox_2.EnumStrings)(typebox_1.Type.Enum(MetricMath));
var TimeUnit;
(function (TimeUnit) {
    TimeUnit["HOUR"] = "hour";
    TimeUnit["DAY"] = "day";
    TimeUnit["WEEK"] = "week";
    TimeUnit["MONTH"] = "month";
    TimeUnit["QUARTER"] = "quarter";
    TimeUnit["YEAR"] = "year";
})(TimeUnit || (exports.TimeUnit = TimeUnit = {}));
exports.TimeUnitType = (0, typebox_2.EnumStrings)(typebox_1.Type.Enum(TimeUnit));
var TimeGranularity;
(function (TimeGranularity) {
    TimeGranularity["HOUR"] = "hour";
    TimeGranularity["DAY"] = "day";
    TimeGranularity["WEEK"] = "week";
    TimeGranularity["MONTH"] = "month";
    TimeGranularity["QUARTER"] = "quarter";
    TimeGranularity["YEAR"] = "year";
})(TimeGranularity || (exports.TimeGranularity = TimeGranularity = {}));
var ChartType;
(function (ChartType) {
    ChartType["LINE"] = "line";
    ChartType["HORIZONTAL"] = "horizontal";
    ChartType["STACKED"] = "stacked";
    ChartType["NUMBER"] = "number";
})(ChartType || (exports.ChartType = ChartType = {}));
var DateRangeType;
(function (DateRangeType) {
    DateRangeType["IN_THE_LAST"] = "IN_THE_LAST";
    DateRangeType["ABSOLUTE"] = "ABSOLUTE";
    DateRangeType["OVER_ALL_TIME"] = "OVER_ALL_TIME";
    DateRangeType["AFTER"] = "AFTER";
    DateRangeType["BEFORE"] = "BEFORE";
})(DateRangeType || (exports.DateRangeType = DateRangeType = {}));
exports.DateRangeTypeType = (0, typebox_2.EnumStrings)(typebox_1.Type.Enum(DateRangeType));
var TimeShiftUnit;
(function (TimeShiftUnit) {
    TimeShiftUnit["FULL_TIME_INTERVAL"] = "full time interval";
    TimeShiftUnit["TIME_GRANULARITY"] = "time granularity";
    TimeShiftUnit["MILLISECOND"] = "millisecond";
    TimeShiftUnit["HOUR"] = "hour";
    TimeShiftUnit["DAY"] = "day";
    TimeShiftUnit["WEEK"] = "week";
    TimeShiftUnit["MONTH"] = "month";
    TimeShiftUnit["QUARTER"] = "quarter";
    TimeShiftUnit["YEAR"] = "year";
})(TimeShiftUnit || (exports.TimeShiftUnit = TimeShiftUnit = {}));
exports.TimeShiftUnitType = (0, typebox_2.EnumStrings)(typebox_1.Type.Enum(TimeShiftUnit));
var GroupValueType;
(function (GroupValueType) {
    GroupValueType["STRING"] = "string";
    GroupValueType["NUMBER"] = "number";
    GroupValueType["ARRAY"] = "array";
    GroupValueType["DATETIME64"] = "datetime64";
})(GroupValueType || (exports.GroupValueType = GroupValueType = {}));
exports.GroupValueTypeType = (0, typebox_2.EnumStrings)(typebox_1.Type.Enum(GroupValueType));
exports.TimeRangeWindowVODef = typebox_1.Type.Object({
    unit: exports.TimeUnitType,
    value: typebox_1.Type.Number({ minimum: 1 }),
});
exports.TimeRangeVODef = typebox_1.Type.Object({
    date_range_type: exports.DateRangeTypeType,
    window: (0, typebox_2.OptionalNullable)(exports.TimeRangeWindowVODef),
    start_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time', description: 'inclusive' })),
    end_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time', description: 'exclusive' })),
});
exports.TimeShiftVODef = typebox_1.Type.Object({
    type: typebox_1.Type.Literal('minus'),
    unit: exports.TimeShiftUnitType,
    value: typebox_1.Type.Optional(typebox_1.Type.Number({ minimum: 1 })),
    from_end_of_period: (0, typebox_2.OptionalNullable)(typebox_1.Type.Boolean()),
});
exports.TimeRangeWithShiftVODef = typebox_1.Type.Composite([
    exports.TimeRangeVODef,
    typebox_1.Type.Object({
        shift: typebox_1.Type.Optional(exports.TimeShiftVODef),
    }),
]);
const toTimeShiftUnit = (timeUnit) => {
    switch (timeUnit) {
        case TimeUnit.HOUR:
            return TimeShiftUnit.HOUR;
        case TimeUnit.DAY:
            return TimeShiftUnit.DAY;
        case TimeUnit.WEEK:
            return TimeShiftUnit.WEEK;
        case TimeUnit.MONTH:
            return TimeShiftUnit.MONTH;
        case TimeUnit.QUARTER:
            return TimeShiftUnit.QUARTER;
        case TimeUnit.YEAR:
            return TimeShiftUnit.YEAR;
        default:
            break;
    }
    throw new Error(`Could not convert "${timeUnit}" to time shift unit`);
};
exports.toTimeShiftUnit = toTimeShiftUnit;
