"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportsQueryPerObjectResponseDef = exports.ReportsQueryPerObjectResultItemDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
exports.ReportsQueryPerObjectResultItemDef = typebox_1.Type.Object({
    timestamp: typebox_1.Type.String({ format: 'date-time' }),
    event_name: typebox_1.Type.String(),
    record_id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    record_name: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    object_slug: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    metric: typebox_1.Type.Number(),
});
exports.ReportsQueryPerObjectResponseDef = typebox_1.Type.Object({
    result: typebox_1.Type.Array(exports.ReportsQueryPerObjectResultItemDef),
});
