import { format, formatDistanceToNow, parseISO } from 'date-fns';
import { Tooltip } from '../ui/Tooltip/Tooltip';
import { FC } from 'react';

interface LastUpdatedProps {
  isoDate: string;
}
export const LastUpdated: FC<LastUpdatedProps> = ({ isoDate }) => {
  const distanceTime = formatDistanceToNow(new Date(isoDate), { addSuffix: true });
  const exactTime = format(parseISO(isoDate), 'dd/MM/yyyy HH:mm');

  return (
    <Tooltip content={exactTime}>
      <div>{distanceTime}</div>
    </Tooltip>
  );
};
