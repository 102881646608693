"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashboardPanelResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const dashboardPanel_1 = require("../model/dashboardPanel");
const typebox_2 = require("../typebox");
exports.DashboardPanelResponseDef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: typebox_1.Type.String(),
        order: typebox_1.Type.Number(),
        configuration: typebox_1.Type.Object({
            report_id: typebox_1.Type.String({ format: 'uuid' }),
            icon: (0, typebox_2.OptionalNullable)(typebox_1.Type.Enum(dashboardPanel_1.DashboardPanelIcon)),
            icon_color: (0, typebox_2.OptionalNullable)(typebox_1.Type.Enum(dashboardPanel_1.DashboardPanelIconColor)),
            type: (0, typebox_2.OptionalNullable)(typebox_1.Type.Enum(dashboardPanel_1.DashboardPanelType)),
        }),
    }),
]);
