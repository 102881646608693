"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetricsRegistry = void 0;
class MetricsRegistry {
    constructor(promClient) {
        this.promClient = promClient;
    }
    getOrCreateCounter(counterInfo) {
        const { register, Counter } = this.promClient;
        const existingCounter = register.getSingleMetric(counterInfo.name);
        if (existingCounter) {
            return existingCounter;
        }
        return new Counter({
            name: counterInfo.name,
            help: counterInfo.help,
            labelNames: counterInfo.labelNames,
        });
    }
}
exports.MetricsRegistry = MetricsRegistry;
