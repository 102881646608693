"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IngestRecordsRequestDef = exports.IngestRecordRequestDef = void 0;
const typebox_1 = require("../typebox");
const typebox_2 = require("@sinclair/typebox");
exports.IngestRecordRequestDef = typebox_2.Type.Object({
    id: typebox_2.Type.String({
        description: 'The ID of the record.',
    }),
    slug: typebox_2.Type.String({
        description: `The slug of the record's workspace object.`,
    }),
    properties: (0, typebox_1.OptionalNullable)(typebox_2.Type.Object({
        set: (0, typebox_1.OptionalNullable)(typebox_1.NestedObjectDef),
        set_once: (0, typebox_1.OptionalNullable)(typebox_1.NestedObjectDef),
    })),
}, {
    additionalProperties: false,
    examples: [
        {
            id: '5272e0f654fa423f1ad5a541',
            slug: 'users',
            properties: {
                set: { first_name: 'John', last_name: 'Johnson', active: true },
                set_once: { email_address: 'john@example.com' },
            },
        },
    ],
});
exports.IngestRecordsRequestDef = typebox_2.Type.Object({
    records: typebox_2.Type.Array(exports.IngestRecordRequestDef),
}, {
    additionalProperties: false,
    examples: [
        {
            records: [
                {
                    id: '5272e0f654fa423f1ad5a541',
                    slug: 'users',
                    properties: {
                        set: { first_name: 'John', last_name: 'Johnson', active: true },
                        set_once: { email_address: 'john@example.com' },
                    },
                },
            ],
        },
    ],
});
