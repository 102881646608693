"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClickHouseNonArrayDataTypes = exports.ClickHouseArrayDataTypes = exports.ClickHouseDataTypeToArrayType = exports.ClickHouseDataType = void 0;
var ClickHouseDataType;
(function (ClickHouseDataType) {
    ClickHouseDataType["UUID"] = "UUID";
    ClickHouseDataType["DATETIME64"] = "DateTime64";
    ClickHouseDataType["STRING"] = "String";
    ClickHouseDataType["BOOLEAN"] = "Boolean";
    ClickHouseDataType["INT64"] = "Int64";
    ClickHouseDataType["UINT64"] = "UInt64";
    ClickHouseDataType["FLOAT64"] = "Float64";
    ClickHouseDataType["UUID_ARRAY"] = "Array(UUID)";
    ClickHouseDataType["STRING_ARRAY"] = "Array(String)";
    ClickHouseDataType["BOOLEAN_ARRAY"] = "Array(Boolean)";
    ClickHouseDataType["INT64_ARRAY"] = "Array(Int64)";
    ClickHouseDataType["FLOAT64_ARRAY"] = "Array(Float64)";
    ClickHouseDataType["UINT64_ARRAY"] = "Array(UInt64)";
    ClickHouseDataType["IDENTIFIER"] = "Identifier";
})(ClickHouseDataType || (exports.ClickHouseDataType = ClickHouseDataType = {}));
exports.ClickHouseDataTypeToArrayType = {
    [ClickHouseDataType.UUID]: ClickHouseDataType.UUID_ARRAY,
    [ClickHouseDataType.STRING]: ClickHouseDataType.STRING_ARRAY,
    [ClickHouseDataType.BOOLEAN]: ClickHouseDataType.BOOLEAN_ARRAY,
    [ClickHouseDataType.INT64]: ClickHouseDataType.INT64_ARRAY,
    [ClickHouseDataType.FLOAT64]: ClickHouseDataType.FLOAT64_ARRAY,
    [ClickHouseDataType.UINT64]: ClickHouseDataType.UINT64_ARRAY,
};
exports.ClickHouseArrayDataTypes = [
    ClickHouseDataType.UUID_ARRAY,
    ClickHouseDataType.STRING_ARRAY,
    ClickHouseDataType.BOOLEAN_ARRAY,
    ClickHouseDataType.INT64_ARRAY,
    ClickHouseDataType.FLOAT64_ARRAY,
    ClickHouseDataType.UINT64_ARRAY,
];
exports.ClickHouseNonArrayDataTypes = Object.values(ClickHouseDataType).filter((item) => !exports.ClickHouseArrayDataTypes.includes(item));
