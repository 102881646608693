const workspaceIdKey = 'current-workspace-id';

export const getStoredSelectedWorkspaceId = () => localStorage.getItem(workspaceIdKey);
export const storeSelectedWorkspaceId = (workspaceId: string | null) => {
  if (workspaceId) {
    localStorage.setItem(workspaceIdKey, workspaceId);
  } else {
    localStorage.removeItem(workspaceIdKey);
  }
};
