import { PageHeading } from '../../../components/PageHeading';
import { Link } from 'react-router-dom';
import { SegmentsTable } from '../components/SegmentsTable';
import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { SegmentsListData } from '@bigdelta/lib-api-client';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { Button } from '../../../shared/ui/Button/Button';
import { useQueryKeys } from '../../auth/hooks/useQueryKeys.ts';
import { CenteredLoader } from '../../../shared/components/CenteredLoader.tsx';
import { EmptyState } from '../../../shared/components/EmptyState.tsx';
import PieChartIcon from '../../../assets/icons/pie-chart-01.svg?react';
import PlusIcon from '../../../assets/icons/plus.svg?react';
import ListIcon from '../../../assets/icons/list.svg?react';
import { bigdeltaAPIClient } from '../../../client/bigdeltaAPIClient.ts';

const EMPTY_STATE_STAGES = [
  {
    icon: PieChartIcon,
    title: 'Create segment',
    description: 'Filter in any record data table.',
  },
  {
    icon: PlusIcon,
    title: 'Save segment',
    description: 'Save filter setup for later.',
  },
  {
    icon: ListIcon,
    title: 'Segment list',
    description: 'Create, manage and filter.',
  },
];

export const SegmentsPage = () => {
  const queryKeys = useQueryKeys();

  const { currentWorkspaceId } = useWorkspace();

  const segmentsListQuery = useQuery({
    queryKey: queryKeys.segments(),
    queryFn: () => bigdeltaAPIClient.v1.segmentsList({ workspace_id: currentWorkspaceId }),
  });

  const isEmpty = !segmentsListQuery?.data?.items || segmentsListQuery?.data?.items.length === 0;

  const segmentsObjectsMap = useMemo(() => {
    return segmentsListQuery?.data?.items.reduce(
      (acc, segment) => {
        const objectId = segment.records_query?.query.resource.id;
        if (objectId) {
          acc[objectId] = [...(acc[objectId] ?? []), segment];
        }
        return acc;
      },
      {} as Record<string, SegmentsListData['items']>
    );
  }, [segmentsListQuery?.data]);

  const eventsSegments = segmentsListQuery?.data?.items.filter((segment) => !!segment.events_query);

  return (
    <div className="flex h-screen w-full flex-col">
      {!segmentsListQuery.isInitialLoading && (
        <>
          <div className="flex flex-col">
            <div className="flex items-center justify-between" style={{ zIndex: 1 }}>
              <PageHeading breadcrumbs={[{ label: 'Segments' }]} />
              <Link to="/records">
                <Button label="Create segment" intent={isEmpty ? 'secondary' : 'brand'} size="lg" />
              </Link>
            </div>
            <hr className="my-4 h-px bg-m-gray-300" />
            {!isEmpty && (
              <>
                {Object.entries(segmentsObjectsMap ?? {}).map(([objectId, objectSegments]) => {
                  return <SegmentsTable objectId={objectId} segments={objectSegments} entityType="RECORDS" />;
                })}
                {eventsSegments?.length ? <SegmentsTable segments={eventsSegments} entityType="EVENTS" /> : null}
              </>
            )}
            {isEmpty && (
              <EmptyState heading="Slice your data in Segments" stages={EMPTY_STATE_STAGES}>
                <Link to="/records">
                  <Button intent="brand" size="xl" label="Create segment" />
                </Link>
              </EmptyState>
            )}
          </div>
        </>
      )}
      {segmentsListQuery.isInitialLoading && <CenteredLoader />}
    </div>
  );
};
