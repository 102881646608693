import { QuickAccessLinkType } from '@bigdelta/lib-shared';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export interface SavedLink {
  id: string;
  type: QuickAccessLinkType;
  order: number;
  label: string;
  data: {
    link?: {
      to: string;
    };
    object?: {
      workspaceObjectId: string;
    };
    record?: { workspaceObjectId: string; recordId: string };
    report?: { reportId: string };
    dashboard?: { dashboardId: string };
    automation?: { automationId: string };
    integration?: { integrationId: string };
  };
}

type DrawerState = {
  mobileOpen: boolean;
  collapsed: boolean;
  toggleCollapse: () => void;
  toggleMobileMenu: () => void;
  closeMobileMenu: () => void;

  isOpen: boolean;
  toggleIsOpen: () => void;
};

export const useDrawerStore = create<DrawerState>()(
  immer(
    devtools((set) => ({
      mobileOpen: false,
      collapsed: false,

      toggleCollapse: () =>
        set((state) => {
          state.collapsed = !state.collapsed;
        }),
      toggleMobileMenu: () =>
        set((state) => {
          state.mobileOpen = !state.mobileOpen;
        }),
      closeMobileMenu: () =>
        set((state) => {
          state.mobileOpen = false;
        }),

      isOpen: true,
      toggleIsOpen: () => set((state) => ({ isOpen: !state.isOpen })),
    }))
  )
);
