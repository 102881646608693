"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkspaceObjectsResponseDef = exports.WorkspaceObjectResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_1 = require("../model");
const typebox_2 = require("../typebox");
exports.WorkspaceObjectResponseDef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: typebox_1.Type.String(),
        account_id: typebox_1.Type.String(),
        workspace_id: typebox_1.Type.String(),
        api_slug: typebox_1.Type.String(),
        singular_noun: typebox_1.Type.String(),
        plural_noun: typebox_1.Type.String(),
        object_type: typebox_1.Type.Enum(model_1.WorkspaceObjectType),
        status: typebox_1.Type.Enum(model_1.WorkspaceObjectStatus),
        label_properties: typebox_1.Type.Array(typebox_1.Type.String()),
        icon_property: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
        records_changelog_enabled: typebox_1.Type.Boolean(),
        properties: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(model_1.WorkspaceObjectPropertiesSchemaDef)),
    }),
]);
exports.WorkspaceObjectsResponseDef = typebox_1.Type.Object({
    objects: typebox_1.Type.Array(exports.WorkspaceObjectResponseDef),
});
