"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentImportSubscriptionCompactResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const paymentImportCustomerCompactResponse_1 = require("./paymentImportCustomerCompactResponse");
exports.PaymentImportSubscriptionCompactResponseDef = typebox_1.Type.Object({
    id: typebox_1.Type.String(),
    name: typebox_1.Type.String(),
    customer: paymentImportCustomerCompactResponse_1.PaymentImportCustomerCompactResponseDef,
});
