import { ButtonHTMLAttributes, ReactNode, forwardRef } from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';
import LoadingIcon from '../../../assets/icons/loading-06.svg?react';
import { SVGComponent } from '../../../types';

const buttonVariants = cva(
  'group/button flex justify-center items-center gap-x-2 rounded-lg focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-m-blue-300',
  {
    variants: {
      intent: {
        brand:
          'bg-m-lime-600 border border-transparent hover:bg-m-lime-700 radix-state-open:bg-m-lime-700 disabled:bg-m-lime-400 disabled:text-m-olive-400 text-m-olive-700',
        primary:
          'bg-m-blue-100 border border-transparent hover:bg-m-blue-200 radix-state-open:bg-m-blue-200 disabled:bg-m-blue-100 disabled:text-m-blue-400 text-m-blue-600',
        secondary:
          'bg-m-white border border-m-olive-100 hover:bg-m-gray-300 radix-state-open:bg-m-gray-300 disabled:text-m-olive-400 disabled:bg-m-white text-m-olive-700',
        tertiary:
          'bg-m-white border border-transparent hover:bg-m-gray-300 radix-state-open:bg-m-gray-300 disabled:text-m-olive-400 disabled:bg-m-white text-m-olive-700',
        destructive: 'bg-m-red-600 text-m-white border border-transparent',
      },
      size: {
        xl: 'px-4 py-2.5',
        lg: 'px-4 py-2',
        md: 'px-4 py-2',
        sm: 'px-2.5 py-1.5',
        xs: 'px-2 py-1',
      },
      equalPadding: {
        true: '',
        false: '',
      },
      fullWidth: {
        true: 'w-full',
      },
    },
    compoundVariants: [
      {
        size: 'xl',
        equalPadding: true,
        className: 'px-2.5 py-2.5',
      },
      {
        size: 'lg',
        equalPadding: true,
        className: 'px-2 py-2',
      },
      {
        size: 'md',
        equalPadding: true,
        className: 'px-2 py-2',
      },
      {
        size: 'sm',
        equalPadding: true,
        className: 'px-1.5 py-1.5',
      },
      {
        size: 'xs',
        equalPadding: true,
        className: 'px-1 py-1',
      },
    ],
    defaultVariants: {
      intent: 'primary',
      size: 'md',
      equalPadding: false,
    },
  }
);

const buttonIconVariants = cva('', {
  variants: {
    intent: {
      brand: 'text-m-olive-700 group-disabled/button:text-m-olive-400',
      primary: 'text-m-blue-600 group-disabled/button:text-m-blue-400',
      secondary: 'text-m-olive-400 group-disabled/button:text-m-olive-400',
      tertiary: 'text-m-olive-400 group-disabled/button:text-m-olive-400',
      destructive: 'text-m-white',
    },
    size: {
      xl: 'h-5 w-5',
      lg: 'h-5 w-5',
      md: 'h-4 w-4',
      sm: 'h-4 w-4',
      xs: 'h-4 w-4',
    },
  },
  defaultVariants: {
    intent: 'primary',
    size: 'md',
  },
});

const loadingIconVariants = cva('', {
  variants: {
    intent: {
      brand: 'text-m-olive-700 group-disabled/button:text-m-olive-400',
      primary: 'text-m-blue-600 group-disabled/button:text-m-blue-400',
      secondary: 'text-m-olive-400 group-disabled/button:text-m-olive-400',
      tertiary: 'text-m-olive-400 group-disabled/button:text-m-olive-400',
      destructive: 'text-m-white',
    },
    size: {
      xl: 'h-5 w-5',
      lg: 'h-4 w-4',
      md: 'h-4 w-4',
      sm: 'h-3 w-3',
      xs: 'h-2 w-2',
    },
  },
  defaultVariants: {
    intent: 'primary',
    size: 'md',
  },
});

const textButtonVariants = cva('whitespace-nowrap', {
  variants: {
    size: {
      xl: 'text-lg font-medium',
      lg: 'text-md font-medium',
      md: 'text-sm font-medium',
      sm: 'text-sm font-regular',
      xs: 'text-xs font-regular',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export interface ButtonComponentProps extends ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  label?: ReactNode;
  postLabel?: ReactNode;
  loading?: boolean;
  leadingIcon?: SVGComponent;
  trailingIcon?: SVGComponent;
}

export const Button = forwardRef<HTMLButtonElement, ButtonComponentProps>(
  (
    { label, postLabel, leadingIcon: LeadingIcon, trailingIcon: TrailingIcon, loading, intent, equalPadding, size, fullWidth, className, ...props },
    ref
  ) => {
    return (
      <button
        {...props}
        className={twMerge(
          'disabled:cursor-not-allowed',
          buttonVariants({
            intent,
            size,
            equalPadding,
            fullWidth,
          }),
          className
        )}
        ref={ref}
      >
        {loading && <LoadingIcon className={twMerge('animate-spin', loadingIconVariants({ intent, size }))} />}
        {LeadingIcon && <LeadingIcon className={buttonIconVariants({ intent, size })} />}
        {label && <span className={textButtonVariants({ size })}>{label}</span>}
        {postLabel}
        {TrailingIcon && <TrailingIcon className={buttonIconVariants({ intent, size })} />}
      </button>
    );
  }
);
