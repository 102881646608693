import { createContext, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useUser } from '../hooks/useUser';
import { getStoredSelectedAccountId, storeSelectedAccountId } from '../../../shared/utils/selectedAccountId.ts';

interface Account {
  id: string;
  name: string;
}

export interface AccountContextType {
  currentAccountId: string;
  currentAccountName: string;
  selectAccount: (accountId: string) => void;
}

export const AccountContext = createContext<AccountContextType | null>(null);

export const AccountProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [currentAccount, setCurrentAccount] = useState<Account>();
  const { accounts } = useUser();

  const selectAccount = useCallback(
    (accountId: string) => {
      setCurrentAccount(accounts?.find((a) => a.id === accountId));
      storeSelectedAccountId(accountId);
    },
    [accounts]
  );

  useEffect(() => {
    const storedAccountId = getStoredSelectedAccountId();

    let accountId = accounts?.[0]?.id;
    if (storedAccountId && accounts?.some((account) => account.id === storedAccountId)) {
      accountId = storedAccountId;
    }

    if (accountId) {
      selectAccount(accountId);
    }
  }, [accounts, selectAccount]);

  if (!currentAccount) {
    return null;
  }

  return (
    <AccountContext.Provider
      value={{
        currentAccountId: currentAccount.id,
        currentAccountName: currentAccount.name,
        selectAccount,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
