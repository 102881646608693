import { TableResourceType, TimeRangeVO } from '@bigdelta/lib-shared';
import { ReportTimerangeData } from '../features/reports/types';

type EntityType =
  | 'scenario'
  | 'relationship'
  | 'metric-tree'
  | 'document-relationship'
  | 'object'
  | 'object-relationship'
  | 'metadata'
  | 'object-record'
  | 'event'
  | 'metric'
  | 'quick-access-link'
  | 'template';

const queryKeysFactory = (accountId: string, workspaceId: string) => {
  const account = () => ['account', accountId];
  const workspace = () => ['workspace', workspaceId];

  const withWorkspace = (...keys: (any | undefined)[]) => [...workspace(), ...keys];
  const single = (type: EntityType, ...keys: any[]) => [...workspace(), type, 'single', ...keys];
  const list = (type: EntityType, ...keys: any[]) => [...workspace(), type, 'list', ...keys];

  const metrics = () => [...workspace(), 'metrics'];
  const members = () => [...workspace(), 'members'];
  const invitation = (invitationToken: string) => ['invitation', invitationToken];
  const invitations = () => [...account(), 'invitations'];
  const dashboard = (dashboardId: string) => [...workspace(), 'dashboard', dashboardId];
  const dashboards = () => [...workspace(), 'dashboards'];
  const segment = (segmentId: string | null) => [...workspace(), 'segments', segmentId];
  const insights = () => [...workspace(), 'insights'];
  const insight = (id?: string) => [...workspace(), 'insights', id];
  const opportunityCreateReports = () => [...workspace(), 'opportunity', 'create', 'reports'];
  const opportunities = () => [...workspace(), 'opportunities'];
  const opportunity = (id?: string) => [...workspace(), 'opportunities', id];
  const projects = () => [...workspace(), 'projects'];
  const reports = () => [...workspace(), 'reports'];
  const journey = (id: string, slug: string) => [...workspace(), 'reports', 'journey', id, slug];
  const segments = () => [...workspace(), 'segments'];
  const report = (id?: string) => [...workspace(), 'reports', id];
  const queryReport = (id: string, timeRange: string, granularity: string, timerangeData: ReportTimerangeData | null) => [
    ...workspace(),
    'reports',
    id,
    'query',
    timeRange,
    granularity,
    timerangeData,
  ];
  const project = (id?: string) => [...workspace(), 'projects', id];
  const metric = (id?: string) => single('metric', id);
  const trackingKeys = () => [...workspace(), 'trackingKeys'];
  const apiKeys = () => [...workspace(), 'apiKeys'];
  const integrations = () => [...workspace(), 'integrations'];
  const integration = (id?: string) => [...workspace(), 'integrations', id];
  const automations = () => [...workspace(), 'automations'];
  const automation = (id?: string) => [...workspace(), 'automations', id];
  const object = (id: string) => [...workspace(), 'object', id];
  const objectRecordsCount = () => [...workspace(), 'objectRecordsCount'];
  const relationships = () => [...workspace(), 'relationships'];
  const billing = () => [...account(), 'billing'];
  const eventRecords = (query: unknown) => [...workspace(), 'eventRecords', query];
  const tableLayout = (resourceType: TableResourceType, id?: string) => [...workspace(), 'table-layout', resourceType, id];
  const resolveLabels = (slug: string, remoteIds: string[]) => [...workspace(), 'resolve-labels', slug, ...remoteIds];
  const stageValues = (objectId: string, propertyName: string) => [...workspace(), 'stage-values', objectId, propertyName];
  const boardLayout = (objectId: string, propertyName: string) => [...workspace(), 'board-layout', objectId, propertyName];
  const integrationData = (id: string, data: string, query?: string) => [...integration(id), data, query];
  const lifecycleReport = (time: TimeRangeVO) => [...workspace(), 'lifecycle-report', time];

  return {
    account,
    workspace,
    metrics,
    members,
    dashboard,
    dashboards,
    eventRecords,
    segment,
    insights,
    insight,
    opportunityCreateReports,
    opportunities,
    opportunity,
    withWorkspace,
    projects,
    project,
    reports,
    journey,
    report,
    queryReport,
    segments,
    single,
    list,
    metric,
    trackingKeys,
    apiKeys,
    integrations,
    invitation,
    invitations,
    integration,
    automation,
    automations,
    object,
    objectRecordsCount,
    relationships,
    resolveLabels,
    billing,
    tableLayout,
    stageValues,
    boardLayout,
    integrationData,
    lifecycleReport,
  };
};

export default queryKeysFactory;
