"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const model_1 = require("../model");
const types_1 = require("../types");
const value_object_1 = require("../value-object");
const reportTemplatesQueryRequest_1 = require("../value-object/reportTemplatesQueryRequest");
const chartColorMode_1 = require("../types/chartColorMode");
exports.ReportDef = typebox_1.Type.Composite([
    model_1.TimestampedModelDef,
    typebox_1.Type.Object({
        id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
        accountId: typebox_1.Type.String({ format: 'uuid' }),
        workspaceId: typebox_1.Type.String({ format: 'uuid' }),
        title: typebox_1.Type.Optional(typebox_1.Type.String()),
        description: typebox_1.Type.Optional(typebox_1.Type.String()),
        details: typebox_1.Type.Optional(typebox_1.Type.String()),
        owner: typebox_1.Type.Optional(model_1.MemberDef),
        createdBy: typebox_1.Type.Optional(model_1.MemberDef),
        updatedBy: typebox_1.Type.Optional(model_1.MemberDef),
        documentGroups: (0, typebox_2.OptionalNullable)(typebox_1.Type.Array(model_1.DocumentGroupDef)),
        type: typebox_1.Type.Optional(typebox_1.Type.Enum(types_1.ReportTypeVO)),
        query: typebox_1.Type.Optional(value_object_1.ReportsQueryRequestDef),
        groupingConfig: (0, typebox_2.OptionalNullable)(value_object_1.ReportGroupingConfigVODef),
        colorMode: (0, typebox_2.OptionalNullable)(typebox_1.Type.Enum(chartColorMode_1.ChartColorMode)),
        context: (0, typebox_2.OptionalNullable)(typebox_1.Type.Enum(types_1.Context)),
        template: (0, typebox_2.OptionalNullable)(reportTemplatesQueryRequest_1.ReportTemplatesQueryRequestDef),
    }),
]);
