"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentImportTransactionResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const paymentImportSubscriptionCompactResponse_1 = require("./paymentImportSubscriptionCompactResponse");
const paymentImportTransaction_1 = require("../../../model/payment-import/paymentImportTransaction");
exports.PaymentImportTransactionResponseDef = typebox_1.Type.Composite([
    typebox_1.Type.Omit(paymentImportTransaction_1.PaymentImportTransactionDef, ['subscription_id']),
    typebox_1.Type.Object({
        subscription: paymentImportSubscriptionCompactResponse_1.PaymentImportSubscriptionCompactResponseDef,
    }),
]);
