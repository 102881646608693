"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BoardLayoutConfigDef = exports.BoardSortDef = exports.RelationshipSortDef = exports.PropertySortDef = exports.BoardColumnConfigDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const timestampedModel_1 = require("./timestampedModel");
const types_1 = require("../types");
const tableLayoutConfig_1 = require("./tableLayoutConfig");
exports.BoardColumnConfigDef = typebox_1.Type.Object({
    id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
    property_value_id: typebox_1.Type.String({ format: 'uuid' }),
    enabled: typebox_1.Type.Boolean(),
});
exports.PropertySortDef = typebox_1.Type.Object({
    property_id: typebox_1.Type.String(),
    property_name: typebox_1.Type.String(),
    property_type: (0, typebox_2.OptionalNullable)(types_1.ResourcePropertyTypeType),
});
exports.RelationshipSortDef = typebox_1.Type.Recursive((Self) => typebox_1.Type.Object({
    type: typebox_1.Type.Enum(tableLayoutConfig_1.TableColumnType),
    name: typebox_1.Type.String(),
    [tableLayoutConfig_1.TableColumnType.PROPERTY]: (0, typebox_2.OptionalNullable)(exports.PropertySortDef),
    [tableLayoutConfig_1.TableColumnType.RELATIONSHIP]: (0, typebox_2.OptionalNullable)(Self),
}), {
    $id: 'RelationshipSortDef',
});
exports.BoardSortDef = typebox_1.Type.Object({
    type: typebox_1.Type.Enum(tableLayoutConfig_1.TableColumnType),
    direction: typebox_1.Type.Enum(tableLayoutConfig_1.TableSortDirection),
    property: (0, typebox_2.OptionalNullable)(exports.PropertySortDef),
    relationship: (0, typebox_2.OptionalNullable)(exports.RelationshipSortDef),
});
exports.BoardLayoutConfigDef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
        account_id: typebox_1.Type.String({ format: 'uuid' }),
        workspace_id: typebox_1.Type.String({ format: 'uuid' }),
        member_id: typebox_1.Type.String({ format: 'uuid' }),
        workspace_object_id: typebox_1.Type.String({ format: 'uuid' }),
        property_name: typebox_1.Type.String(),
        sort: (0, typebox_2.OptionalNullable)(exports.BoardSortDef),
        columns: typebox_1.Type.Array(exports.BoardColumnConfigDef),
    }),
    timestampedModel_1.TimestampedModelDef,
]);
