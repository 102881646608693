"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthorDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const types_1 = require("../types");
exports.AuthorDef = typebox_1.Type.Object({
    type: typebox_1.Type.Enum(types_1.AuthorType),
    id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
});
