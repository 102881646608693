"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrivateRecordPropertyModificationRequestDef = exports.RecordPropertyModificationRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_1 = require("../model");
exports.RecordPropertyModificationRequestDef = typebox_1.Type.Object({
    path: typebox_1.Type.String(),
    value: typebox_1.Type.Array(typebox_1.Type.String()),
});
exports.PrivateRecordPropertyModificationRequestDef = typebox_1.Type.Intersect([
    exports.RecordPropertyModificationRequestDef,
    typebox_1.Type.Object({
        modified_by: model_1.AuthorDef,
    }),
]);
