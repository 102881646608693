"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateDashboardPanelRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const model_1 = require("../model");
exports.CreateDashboardPanelRequestDef = typebox_1.Type.Object({
    order: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
    configuration: typebox_1.Type.Object({
        report_id: typebox_1.Type.String({ format: 'uuid' }),
        icon: (0, typebox_2.OptionalNullable)(typebox_1.Type.Enum(model_1.DashboardPanelIcon)),
        icon_color: (0, typebox_2.OptionalNullable)(typebox_1.Type.Enum(model_1.DashboardPanelIconColor)),
        type: (0, typebox_2.OptionalNullable)(typebox_1.Type.Enum(model_1.DashboardPanelType)),
    }),
});
