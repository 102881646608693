import { WorkspaceObjectType } from '@bigdelta/lib-shared';

import StoreIcon from '../../assets/icons/building-02.svg?react';
import UsersIcon from '../../assets/icons/users-01.svg?react';
import UserIcon from '../../assets/icons/user-01.svg?react';
import BuildingIcon from '../../assets/icons/building-05.svg?react';
import FeedbackIcon from '../../assets/icons/message-chat-circle.svg?react';
import CustomIcon from '../../assets/icons/file-plus-02.svg?react';

import DefaultObjectIcon from '../../assets/icons/file-plus-02.svg?react';
import { SVGComponent } from '../../types';

const WorkspaceObjectIcons = {
  [WorkspaceObjectType.ACCOUNT]: BuildingIcon,
  [WorkspaceObjectType.COMPANIES]: StoreIcon,
  [WorkspaceObjectType.CUSTOM]: CustomIcon,
  [WorkspaceObjectType.CONVERSATION]: FeedbackIcon,
  [WorkspaceObjectType.PEOPLE]: UsersIcon,
  [WorkspaceObjectType.USER]: UserIcon,
};

export const getWorkspaceObjectIcon = (objectType?: string): SVGComponent => {
  if (!objectType) {
    return DefaultObjectIcon;
  }

  return WorkspaceObjectIcons[objectType] ?? DefaultObjectIcon;
};
