import { flexRender, Table } from '@tanstack/react-table';
import { FC, useMemo } from 'react';
import { PropsWithClassName } from '../../types';
import { twMerge } from '../../utils/twMerge';

interface ToolsTableProps extends PropsWithClassName {
  data: any;
  table: Table<any>;
  onRowClick?: (data: any) => void;
  enableSelection?: boolean;
  isRowDisabled?: (data: any) => boolean;
}

export const ToolsTable: FC<ToolsTableProps> = ({ data, onRowClick, table, enableSelection, isRowDisabled, className }) => {
  const { rowModel, headerGroups } = useMemo(() => {
    return {
      rowModel: table.getRowModel(),
      headerGroups: table.getHeaderGroups(),
    };
    // Do not remove 'data' from the dependencies list, even if suggested by the IDE, to ensure proper rerendering.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, data]);

  const { rows } = rowModel;

  return (
    <table className={twMerge('w-full table-fixed', className)}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr key={headerGroup.id}>
            {enableSelection && <th key="checkbox" style={{ width: 20 }}></th>}
            {headerGroup.headers.map((header, columnIndex) => (
              <th
                key={header.id}
                className={twMerge('py-3 text-left text-sm font-medium text-m-olive-900', enableSelection && columnIndex === 0 ? 'pl-6' : 'px-6')}
                style={{ width: header.getSize() === Number.MAX_SAFE_INTEGER ? 'auto' : header.getSize() }}
              >
                {flexRender(header.column.columnDef.header, header.getContext())}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {rows.map((row, index) => {
          return (
            <tr
              key={row.id}
              className={twMerge(
                index % 2 === 0 ? 'bg-m-gray-200' : 'bg-m-white',
                onRowClick && (!isRowDisabled || !isRowDisabled(row.original)) ? 'cursor-pointer hover:bg-m-gray-300' : 'cursor-default'
              )}
              onClick={() => {
                if (enableSelection) {
                  row.toggleSelected();
                } else {
                  if (!isRowDisabled || !isRowDisabled(row.original)) {
                    onRowClick && onRowClick(row.original);
                  }
                }
              }}
            >
              {enableSelection && (
                <td>
                  <span className="flex justify-center">
                    <input
                      type="checkbox"
                      tabIndex={-1}
                      className="rounded border border-m-olive-200"
                      checked={row.getIsSelected()}
                      disabled={!row.getCanSelect()}
                      onChange={row.getToggleSelectedHandler()}
                    />
                  </span>
                </td>
              )}
              {row.getVisibleCells().map((cell, columnIndex) => {
                return (
                  <td
                    key={cell.id}
                    style={{ width: cell.column.getSize() === Number.MAX_SAFE_INTEGER ? 'auto' : cell.column.getSize() }}
                    className={twMerge(
                      'overflow-hidden text-ellipsis whitespace-nowrap py-4 text-left text-sm font-regular first:rounded-l-lg last:rounded-r-lg',
                      enableSelection && columnIndex === 0 ? 'pl-6' : 'px-6',
                      isRowDisabled && isRowDisabled(row.original) ? 'text-m-gray-600' : 'text-m-olive-900'
                    )}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
