"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegrationDef = exports.ManualIntegrationStateDef = exports.BigQueryIntegrationStateDef = exports.PaymentImportIntegrationStateDef = exports.PayPalIntegrationStateDef = exports.ShopifyPartnersIntegrationStateDef = exports.PayMeIntegrationStateDef = exports.StripeIntegrationStateDef = exports.ManualIntegrationConfigurationDef = exports.BigQueryIntegrationConfigurationDef = exports.SlackIntegrationConfigurationDef = exports.PaymentImportIntegrationConfigurationDef = exports.PayPalIntegrationConfigurationDef = exports.ShopifyPartnersIntegrationConfigurationDef = exports.PayMeIntegrationConfigurationDef = exports.StripeIntegrationConfigurationDef = exports.BigQuerySyncConfigurationDef = exports.BigQueryRecordSinkConfigurationDef = exports.BigQuerySinkType = exports.BigQuerySyncType = exports.BigQuerySyncStatus = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const timestampedModel_1 = require("./timestampedModel");
const types_1 = require("../types");
var BigQuerySyncStatus;
(function (BigQuerySyncStatus) {
    BigQuerySyncStatus["CREATING_SNAPSHOT"] = "creating_snapshot";
    BigQuerySyncStatus["IDENTIFYING_CHANGES"] = "identifying_changes";
    BigQuerySyncStatus["FETCHING_DATA"] = "fetching_data";
    BigQuerySyncStatus["IMPORTING_DATA"] = "importing_data";
    BigQuerySyncStatus["COMPLETED"] = "completed";
    BigQuerySyncStatus["FAILED"] = "failed";
})(BigQuerySyncStatus || (exports.BigQuerySyncStatus = BigQuerySyncStatus = {}));
var BigQuerySyncType;
(function (BigQuerySyncType) {
    BigQuerySyncType["MIRROR"] = "mirror";
})(BigQuerySyncType || (exports.BigQuerySyncType = BigQuerySyncType = {}));
var BigQuerySinkType;
(function (BigQuerySinkType) {
    BigQuerySinkType["RECORD"] = "record";
})(BigQuerySinkType || (exports.BigQuerySinkType = BigQuerySinkType = {}));
exports.BigQueryRecordSinkConfigurationDef = typebox_1.Type.Object({
    workspace_object_slug: typebox_1.Type.String(),
    dataset: typebox_1.Type.String(),
    table: typebox_1.Type.String(),
    primary_key_column: typebox_1.Type.String(),
});
exports.BigQuerySyncConfigurationDef = typebox_1.Type.Object({
    sync_type: typebox_1.Type.Enum(BigQuerySyncType),
    sink_type: typebox_1.Type.Enum(BigQuerySinkType),
    record: (0, typebox_2.OptionalNullable)(exports.BigQueryRecordSinkConfigurationDef),
});
exports.StripeIntegrationConfigurationDef = typebox_1.Type.Object({
    access_token: typebox_1.Type.String(),
    refresh_token: typebox_1.Type.String(),
    workspace_object_slug: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    customer_mapping_key: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
});
exports.PayMeIntegrationConfigurationDef = typebox_1.Type.Object({
    api_key: typebox_1.Type.String(),
    workspace_object_slug: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
});
exports.ShopifyPartnersIntegrationConfigurationDef = typebox_1.Type.Object({
    api_key: typebox_1.Type.String(),
    organization_id: typebox_1.Type.Number(),
    app_id: typebox_1.Type.Number(),
    workspace_object_slug: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
});
exports.PayPalIntegrationConfigurationDef = typebox_1.Type.Object({
    client_id: typebox_1.Type.String(),
    client_secret: typebox_1.Type.String(),
    workspace_object_slug: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
});
exports.PaymentImportIntegrationConfigurationDef = typebox_1.Type.Object({
    workspace_object_slug: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
});
exports.SlackIntegrationConfigurationDef = typebox_1.Type.Object({
    access_token: typebox_1.Type.String({ format: 'uuid' }),
});
exports.BigQueryIntegrationConfigurationDef = typebox_1.Type.Object({
    gcp_project_id: typebox_1.Type.String({ format: 'uuid' }),
    provided_dataset: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
    provided_location: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
    service_account: typebox_1.Type.Object({
        email: typebox_1.Type.String(),
        key: typebox_1.Type.String(),
    }),
    connector_dataset: typebox_1.Type.String(),
    syncs: typebox_1.Type.Record(typebox_1.Type.String({ format: 'uuid' }), exports.BigQuerySyncConfigurationDef),
});
exports.ManualIntegrationConfigurationDef = typebox_1.Type.Object({
    workspace_object_slug: typebox_1.Type.String(),
});
exports.StripeIntegrationStateDef = typebox_1.Type.Object({
    last_revenue_sync_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
    revenue_sync_watermark_seconds: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
    last_customers_sync_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
    customers_sync_watermark_seconds: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
    last_charges_sync_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
    charges_sync_watermark_seconds: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
    last_refunds_sync_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
    refunds_sync_watermark_seconds: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
    last_token_refresh_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
    next_sync_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
});
exports.PayMeIntegrationStateDef = typebox_1.Type.Object({
    last_revenue_sync_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
    revenue_sync_watermark_seconds: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
    next_sync_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
});
exports.ShopifyPartnersIntegrationStateDef = typebox_1.Type.Object({
    last_revenue_sync_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
    revenue_sync_watermark_seconds: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
    last_customers_sync_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
    customers_sync_watermark_seconds: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
    last_charges_sync_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
    charges_sync_watermark_seconds: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
    last_refunds_sync_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
    refunds_sync_watermark_seconds: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
    next_sync_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
});
exports.PayPalIntegrationStateDef = typebox_1.Type.Object({
    last_revenue_sync_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
    last_payment_notification_received_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
    revenue_sync_watermark_seconds: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
    next_sync_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
});
exports.PaymentImportIntegrationStateDef = typebox_1.Type.Object({
    last_customer_created_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
    last_revenue_update_received_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
});
exports.BigQueryIntegrationStateDef = typebox_1.Type.Object({
    syncs: typebox_1.Type.Record(typebox_1.Type.String({ format: 'uuid' }), typebox_1.Type.Object({
        last_sync_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
        next_sync_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
        latest_snapshot_name: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
        status: (0, typebox_2.OptionalNullable)(typebox_1.Type.Enum(BigQuerySyncStatus)),
    })),
});
exports.ManualIntegrationStateDef = typebox_1.Type.Object({
    last_revenue_sync_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
});
exports.IntegrationDef = typebox_1.Type.Composite([
    timestampedModel_1.TimestampedModelDef,
    typebox_1.Type.Object({
        id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
        account_id: typebox_1.Type.String({ format: 'uuid' }),
        workspace_id: typebox_1.Type.String({ format: 'uuid' }),
        type: typebox_1.Type.Enum(types_1.IntegrationType),
        status: typebox_1.Type.Enum(types_1.IntegrationStatus),
        created_by: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
        deleted_by: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'uuid' })),
        deleted_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
        configuration: typebox_1.Type.Object({
            [types_1.IntegrationType.STRIPE]: typebox_1.Type.Optional(exports.StripeIntegrationConfigurationDef),
            [types_1.IntegrationType.PAYME]: typebox_1.Type.Optional(exports.PayMeIntegrationConfigurationDef),
            [types_1.IntegrationType.SHOPIFY_PARTNERS]: typebox_1.Type.Optional(exports.ShopifyPartnersIntegrationConfigurationDef),
            [types_1.IntegrationType.PAYPAL]: typebox_1.Type.Optional(exports.PayPalIntegrationConfigurationDef),
            [types_1.IntegrationType.SLACK]: typebox_1.Type.Optional(exports.SlackIntegrationConfigurationDef),
            [types_1.IntegrationType.BIGQUERY]: typebox_1.Type.Optional(exports.BigQueryIntegrationConfigurationDef),
            [types_1.IntegrationType.MANUAL]: typebox_1.Type.Optional(exports.ManualIntegrationConfigurationDef),
            [types_1.IntegrationType.PAYMENT_IMPORT]: typebox_1.Type.Optional(exports.PaymentImportIntegrationConfigurationDef),
        }),
        state: (0, typebox_2.OptionalNullable)(typebox_1.Type.Object({
            [types_1.IntegrationType.STRIPE]: typebox_1.Type.Optional(exports.StripeIntegrationStateDef),
            [types_1.IntegrationType.PAYME]: typebox_1.Type.Optional(exports.PayMeIntegrationStateDef),
            [types_1.IntegrationType.SHOPIFY_PARTNERS]: typebox_1.Type.Optional(exports.ShopifyPartnersIntegrationStateDef),
            [types_1.IntegrationType.PAYPAL]: typebox_1.Type.Optional(exports.PayPalIntegrationStateDef),
            [types_1.IntegrationType.BIGQUERY]: typebox_1.Type.Optional(exports.BigQueryIntegrationStateDef),
            [types_1.IntegrationType.MANUAL]: typebox_1.Type.Optional(exports.ManualIntegrationStateDef),
            [types_1.IntegrationType.PAYMENT_IMPORT]: typebox_1.Type.Optional(exports.PaymentImportIntegrationStateDef),
        })),
    }),
]);
