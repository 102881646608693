import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { cleanupAuthenticationData } from '../../../shared/utils/authToken';
import { useUser } from '../../auth/hooks/useUser';
import { tracking, TrackingEvent } from '../../../tracking';
import ChevronDownIcon from '../../../assets/icons/chevron-down.svg?react';

import AccountSettingsIcon from '../../../assets/icons/user-01.svg?react';
import WorkspaceSettingsIcon from '../../../assets/icons/settings-02.svg?react';
import LogoutIcon from '../../../assets/icons/log-out-01.svg?react';

import { DropdownMenuItem } from './DropdownMenuItem';
import { AccountSubmenu } from './AccountSubmenu';
import { twMerge } from '../../../utils/twMerge';

import CollapseIcon from '../../../assets/icons/align-left-01.svg?react';
import ExpandIcon from '../../../assets/icons/align-right-01.svg?react';
import { useDrawerStore } from '../store';
import { useAccount } from '../../auth/hooks/useAccount';
import { useWorkspace } from '../../auth/hooks/useWorkspace';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../routes';
import { Tooltip } from '../../../shared/ui/Tooltip/Tooltip';

export const ProfileMenu = () => {
  const { resetAuth } = useUser();
  const { isOpen, toggleIsOpen } = useDrawerStore();
  const { currentWorkspaceId } = useWorkspace();
  const { workspaces } = useUser();
  const navigate = useNavigate();

  const { currentAccountName } = useAccount();

  const currentWorkspace = workspaces?.find((w) => w.id === currentWorkspaceId);
  const name = currentAccountName?.split('')[0];

  const handleLogout = () => {
    tracking.track(TrackingEvent.LoggedOut);
    cleanupAuthenticationData();
    resetAuth();
    tracking.reset();
  };

  return (
    <div className="flex items-center justify-between">
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <button className="flex min-w-0 items-center gap-x-2 rounded-md p-1 hover:bg-m-coconut-700">
            <div className="h-6 w-6 shrink-0 rounded bg-m-beige-900 text-center text-lg font-medium text-m-white">{name}</div>
            <div className="flex items-center gap-x-1 overflow-hidden">
              <Tooltip content={currentWorkspace?.name}>
                <span className="truncate text-lg font-medium text-m-olive-600">{currentWorkspace?.name}</span>
              </Tooltip>

              <ChevronDownIcon className="h-4 w-4 shrink-0 text-m-coconut-800" />
            </div>
          </button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content className="w-52 rounded-md border border-m-olive-100 bg-m-white p-2 shadow-sm" align="start" sideOffset={4}>
            <AccountSubmenu />
            <DropdownMenu.Separator className="my-1.5 h-px bg-m-olive-100" />
            <DropdownMenuItem onSelect={() => navigate(AppRoutes.SETTINGS_PROFILE)}>
              <AccountSettingsIcon className="text-m-olive-50 h-4 w-4" />
              <span>Account settings</span>
            </DropdownMenuItem>
            <DropdownMenuItem onSelect={() => navigate(AppRoutes.SETTINGS_WORKSPACE_GENERAL)}>
              <WorkspaceSettingsIcon className="text-m-olive-50 h-4 w-4" />
              <span>Workspace settings</span>
            </DropdownMenuItem>
            <DropdownMenu.Separator className="my-1.5 h-px bg-m-olive-100" />
            <DropdownMenuItem onSelect={handleLogout}>
              <LogoutIcon className="text-m-olive-50 h-4 w-4" />
              <span>Logout</span>
            </DropdownMenuItem>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
      <div>
        <button
          className={twMerge(
            'ml-2 rounded border border-m-olive-400 p-0.5 text-m-olive-400 transition-colors duration-300 hover:bg-m-olive-400 hover:text-m-coconut-600'
          )}
          onClick={toggleIsOpen}
        >
          {!isOpen ? <ExpandIcon className="h-3 w-3" /> : <CollapseIcon className="h-3 w-3" />}
        </button>
      </div>
    </div>
  );
};
