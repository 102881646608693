"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toCompactApiKeyResponse = exports.CompactApiKeyResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const apiKeyResponse_1 = require("./apiKeyResponse");
exports.CompactApiKeyResponseDef = typebox_1.Type.Pick(apiKeyResponse_1.ApiKeyResponseDef, ['id', 'name']);
const toCompactApiKeyResponse = (apiKey) => {
    if (!apiKey) {
        return null;
    }
    return {
        id: apiKey.id,
        name: apiKey.name,
    };
};
exports.toCompactApiKeyResponse = toCompactApiKeyResponse;
