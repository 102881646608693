"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateObjectRecordBoardConfigDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const model_1 = require("../model");
const typebox_2 = require("../typebox");
exports.UpdateObjectRecordBoardConfigDef = typebox_1.Type.Object({
    workspace_object_id: typebox_1.Type.String(),
    property_name: typebox_1.Type.String(),
    sort: (0, typebox_2.OptionalNullable)(model_1.BoardSortDef),
    columns: typebox_1.Type.Array(model_1.BoardColumnConfigDef),
});
