"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrivateCreateSubscriptionLifecycleEventRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
const types_1 = require("../types");
exports.PrivateCreateSubscriptionLifecycleEventRequestDef = typebox_1.Type.Object({
    customer_id: typebox_1.Type.String(),
    subscription_group_id: typebox_1.Type.String(),
    subscription_id: typebox_1.Type.String(),
    subscription_name: typebox_1.Type.String(),
    invoice_id: (0, typebox_2.OptionalNullable)(typebox_1.Type.String()),
    period_start: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
    period_end: typebox_1.Type.String({ format: 'date-time' }),
    interval: typebox_1.Type.Enum(types_1.SubscriptionIntervalEnum),
    interval_count: (0, typebox_2.OptionalNullable)(typebox_1.Type.Number()),
    quantity: typebox_1.Type.Number(),
    price_per_unit: typebox_1.Type.Number(),
    currency: typebox_1.Type.String(),
    status: typebox_1.Type.Enum(types_1.SubscriptionStatus),
    timestamp: typebox_1.Type.String({ format: 'date-time' }),
});
