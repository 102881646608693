import { useQuery } from '@tanstack/react-query';
import { useQueryKeys } from '../../features/auth/hooks/useQueryKeys.ts';
import { bigdeltaAPIClient } from '../../client/bigdeltaAPIClient.ts';
import { useWorkspace } from '../../features/auth/hooks/useWorkspace.tsx';

export const useObjectsQuery = ({ includeVirtual = false } = {}) => {
  const { currentWorkspaceId } = useWorkspace();

  const queryKeys = useQueryKeys();

  return useQuery({
    queryKey: queryKeys.list('object', includeVirtual ? 'active_and_virtual' : undefined),
    queryFn: () =>
      bigdeltaAPIClient.v1.objectsList({ workspace_id: currentWorkspaceId ?? '', status: includeVirtual ? 'ACTIVE_AND_VIRTUAL' : undefined }),
    enabled: !!currentWorkspaceId,
  });
};
