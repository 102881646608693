import { FC, PropsWithChildren, ReactNode, SVGProps } from 'react';
import { twMerge } from 'tailwind-merge';

interface EmptyStateStageProps {
  icon: FC<SVGProps<SVGSVGElement>>;
  iconProps?: SVGProps<SVGSVGElement>;
  title: ReactNode;
  description: ReactNode;
}

const EmptyStateStage: FC<EmptyStateStageProps> = ({ icon, title, description, iconProps }) => {
  const Icon = icon;

  return (
    <div className="flex basis-1/3 flex-col items-center gap-y-4">
      <div className="rounded-xl bg-m-gray-200 p-3.5">
        <Icon className={twMerge('h-7 w-7 text-m-blue-600', iconProps?.className)} />
      </div>
      <div className="flex flex-col">
        <h3 className="text-lg font-medium text-m-olive-700">{title}</h3>
        <h4 className="text-sm text-m-gray-600">{description}</h4>
      </div>
    </div>
  );
};

interface EmptyStateProps extends PropsWithChildren {
  heading: string;
  stages: EmptyStateStageProps[];
}

export const EmptyState: FC<EmptyStateProps> = ({ heading, stages, children }) => (
  <div className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 items-center justify-center">
    <div className="flex max-w-4xl flex-col items-center gap-y-16 text-center">
      <h2 className="max-w-xl text-display-md font-semibold text-m-olive-900">{heading}</h2>
      <div className="flex justify-between gap-x-8">
        {stages.map((stage, index) => {
          return <EmptyStateStage key={index} icon={stage.icon} title={stage.title} description={stage.description} iconProps={stage.iconProps} />;
        })}
      </div>
      {children}
    </div>
  </div>
);
