"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecordPropertyModificationDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const propertyOperationType_1 = require("../types/propertyOperationType");
exports.RecordPropertyModificationDef = typebox_1.Type.Object({
    path: typebox_1.Type.String(),
    value: typebox_1.Type.Array(typebox_1.Type.String()),
    modificationType: typebox_1.Type.Enum(propertyOperationType_1.PropertyOperationType),
});
