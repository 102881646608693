import LineChartUpIcon from '../../assets/icons/line-chart-up-01.svg?react';
import BarChartIcon from '../../assets/icons/bar-chart-03.svg?react';
import XIcon from '../../assets/icons/x.svg?react';

const CHART_ICON_MAP = {
  line: LineChartUpIcon,
  stacked: BarChartIcon,
  horizontal: BarChartIcon,
  number: XIcon,
};

export const getChartIcon = (chartType: string) => {
  return CHART_ICON_MAP[chartType] || LineChartUpIcon;
};
