import * as BaseTooltip from '@radix-ui/react-tooltip';
import { cloneElement, forwardRef, isValidElement, PropsWithChildren, ReactElement, ReactNode } from 'react';

interface TooltipProps extends PropsWithChildren {
  content: ReactNode;
  open?: boolean;
  disabled?: boolean;
  asChild?: boolean;
}

export const Tooltip = forwardRef<HTMLButtonElement, TooltipProps>(({ children, content, open, asChild = true, disabled = false, ...props }, ref) => {
  if (disabled) {
    return <>{children}</>;
  }

  const childrenWithRef = isValidElement(children) ? cloneElement(children as ReactElement<any>, { ...props, ref }) : children;

  return (
    <BaseTooltip.Provider delayDuration={150}>
      <BaseTooltip.Root open={open}>
        <BaseTooltip.Trigger asChild={asChild} ref={ref}>
          {childrenWithRef}
        </BaseTooltip.Trigger>
        <BaseTooltip.Portal>
          <BaseTooltip.Content className="rounded-md bg-m-olive-600 px-2 py-1 text-sm text-m-white" sideOffset={8}>
            {content}
            <BaseTooltip.Arrow className="fill-m-olive-600" />
          </BaseTooltip.Content>
        </BaseTooltip.Portal>
      </BaseTooltip.Root>
    </BaseTooltip.Provider>
  );
});
