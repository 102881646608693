"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrivateResetWorkspaceRequestDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const typebox_2 = require("../typebox");
exports.PrivateResetWorkspaceRequestDef = typebox_1.Type.Object({
    workspace_objects: (0, typebox_2.OptionalNullable)(typebox_1.Type.Boolean()),
    metric_library: (0, typebox_2.OptionalNullable)(typebox_1.Type.Boolean()),
    metric_graph_templates: (0, typebox_2.OptionalNullable)(typebox_1.Type.Boolean()),
    dashboard_templates: (0, typebox_2.OptionalNullable)(typebox_1.Type.Boolean()),
    automation_templates: (0, typebox_2.OptionalNullable)(typebox_1.Type.Boolean()),
    plan_models: (0, typebox_2.OptionalNullable)(typebox_1.Type.Boolean()),
});
