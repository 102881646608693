"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toCompactIntegrationResponse = exports.CompactIntegrationResponseDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const integrationResponse_1 = require("./integration/integrationResponse");
exports.CompactIntegrationResponseDef = typebox_1.Type.Pick(integrationResponse_1.IntegrationResponseDef, ['id', 'type']);
const toCompactIntegrationResponse = (integration) => {
    if (!integration) {
        return null;
    }
    return {
        id: integration.id,
        type: integration.type,
    };
};
exports.toCompactIntegrationResponse = toCompactIntegrationResponse;
