import { FC, PropsWithChildren } from 'react';
import { PropsWithClassName } from '../../../types.ts';
import { twMerge } from 'tailwind-merge';

interface NavItemGroupProps extends PropsWithChildren, PropsWithClassName {
  heading?: string;
}

export const NavItemGroup: FC<NavItemGroupProps> = ({ heading, children, className }) => (
  <div className={twMerge('flex flex-col', className)}>
    {heading && <div className="select-none px-2 pb-2 pt-4 text-xs font-medium text-m-gray-600">{heading}</div>}
    <ul className="flex flex-col items-stretch gap-y-px">{children}</ul>
  </div>
);
