"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentImportSubscriptionDef = void 0;
const typebox_1 = require("@sinclair/typebox");
const timestampedModel_1 = require("../timestampedModel");
const typebox_2 = require("../../typebox");
const types_1 = require("../../types");
exports.PaymentImportSubscriptionDef = typebox_1.Type.Composite([
    typebox_1.Type.Object({
        id: typebox_1.Type.String(),
        account_id: typebox_1.Type.String({ format: 'uuid' }),
        workspace_id: typebox_1.Type.String({ format: 'uuid' }),
        integration_id: typebox_1.Type.String({ format: 'uuid' }),
        customer_id: typebox_1.Type.String(),
        name: typebox_1.Type.String(),
        interval: typebox_1.Type.Enum(types_1.SubscriptionIntervalEnum),
        interval_count: typebox_1.Type.Number(),
        start_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
        end_at: (0, typebox_2.OptionalNullable)(typebox_1.Type.String({ format: 'date-time' })),
    }),
    timestampedModel_1.TimestampedModelDef,
]);
